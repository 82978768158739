import React from "react"
import SEO from "../components/seo"
import Layout from "../components/layout"
import 'bootstrap/dist/css/bootstrap.css'

class ContactPage extends React.Component {
  render() {
    return (<Layout>
      <SEO title="Contact" />
      <div className="Site-inner" data-controller="HeaderOverlay" id="yui_3_17_2_1_1538632099177_588">
        <header className="Header Header--bottom Header--overlay">
          <div className="Header-inner Header-inner--bottom" data-nc-group="bottom">
            <div data-nc-container="bottom-left">
            </div>
            <div data-nc-container="bottom-center">
            </div>
            <div data-nc-container="bottom-right">
              <nav className="Header-nav Header-nav--secondary" data-nc-element="secondary-nav"
                data-content-field="navigation">
              </nav>
            </div>
          </div>
        </header>
        <div className="Content-outer" id="yui_3_17_2_1_1538632099177_224">
          <main className="Index" data-collection-id="5b160901758d4658e6571eef" data-controller="IndexFirstSectionHeight, Parallax, IndexNavigation"
            data-controllers-bound="IndexFirstSectionHeight, Parallax, IndexNavigation">
            <section id="contact-us" className="Index-page" data-collection-id="5ad765962b6a288cd1072c53"
              data-parallax-id="5ad765962b6a288cd1072c53" data-edit-main-image="Background">
              <div className="Index-page-content ">
                <div className="sqs-layout sqs-grid-12 columns-12" data-type="page" data-updated-on="1528241759086"
                  id="page-5ad765962b6a288cd1072c53">
                  <div className="row sqs-row">
                    <div className="col sqs-col-12 span-12">
                      <div className="sqs-block html-block sqs-block-html" data-block-type="2" id="block-yui_3_17_2_1_1528212858018_25259">
                        <div className="sqs-block-content">
                          <p className="text-align-center"><strong>Appointment</strong><br /><a href="https://friends.andersonfamilyvineyard.com/public/visit">Schedule</a>
                          </p>
                          <p className="text-align-center"><strong>Address</strong><br /><a href="https://maps.google.com/?q=20120%20NE%20Herring%20Lane%20Newberg%2C%20OR%2097132" target="_blank" rel="noopener noreferrer">20120 NE
                            Herring Lane<br />Newberg, OR
                            97132
                            </a>
                          </p>
                          <p className="text-align-center"><strong>Phone Number</strong><br />503-554-5541</p>
                          <p className="text-align-center"><strong>Email</strong><br /><a href="mailto:info@andersonfamilyvineyard.com">info@andersonfamilyvineyard.com</a></p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            <section id="maps" className="Index-page" data-parallax-original-element=""
              data-collection-id="5ad770921ae6cf8659265ad8" data-parallax-id="5ad770921ae6cf8659265ad8"
              data-edit-main-image="Background">
              <div style={{ height: "500px" }}>
                <iframe title="Anderson Family Vineyards Maps" width="100%" height="100%" frameBorder="0" style={{ border: 0 }}
                  src="https://www.google.com/maps/embed/v1/place?q=20120%20NE%20Herring%20Lane%20Newberg%2C%20OR%2097132&key=AIzaSyCdFuqL-Xt6f3BjKtUynhVSesJAF1-nUNk" allowFullScreen>
                </iframe>
              </div>
            </section>
          </main>
        </div>
      </div>
    </Layout>)
  }
}

export default ContactPage
